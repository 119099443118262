import React, { useEffect, useState} from "react";
import Link from "next/link";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/nextjs-material-kit/components/headerStyle.js";
import logo from "assets/img/getasugarLogo.svg";
import { useRouter } from "next/router";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles({
	...styles,
	regLink: {
		color: "#eee", 
		textDecoration: "underline" ,
		cursor: "pointer"
	}
});

export default function Header(props) {
	const classes = useStyles();
	const router = useRouter();
	const [mobileOpen, setMobileOpen] = useState(false);

	const theme = useTheme();
  const isDownXS = useMediaQuery(theme.breakpoints.down("xs"));
	
	useEffect(() => {
		if (props.changeColorOnScroll) {
			window.addEventListener("scroll", headerColorChange);
		}
		return function cleanup() {
			if (props.changeColorOnScroll) {
				window.removeEventListener("scroll", headerColorChange);
			}
		};
	});
	
	const headerColorChange = () => {
		const { color, changeColorOnScroll } = props;
		const windowsScrollTop = window.scrollY;
		if (windowsScrollTop > changeColorOnScroll.height) {
			document.body
				.getElementsByTagName("header")[0]
				.classList.remove(classes[color]);
			document.body
				.getElementsByTagName("header")[0]
				.classList.add(classes[changeColorOnScroll.color]);
		} else {
			document.body
				.getElementsByTagName("header")[0]
				.classList.add(classes[color]);
			document.body
				.getElementsByTagName("header")[0]
				.classList.remove(classes[changeColorOnScroll.color]);
		}
	};
	const { color, rightLinks, leftLinks, brand, fixed, absolute, isPromo, promo } = props;
	const appBarClasses = classNames({
		[classes.appBar]: true,
		[classes[color]]: color,
		[classes.absolute]: absolute,
		[classes.fixed]: fixed,
	});
	const brandComponent = (
		<Link href="/" as="/">
			<img
				src={logo.src}
				width="160"
				alt="getasugar.de"
				className={classes.isLogo}
			/>
		</Link>
	);

	let text = `Bonus für Sugar-Forum Mitglieder aktiviert: Doppelte Coins ${!isDownXS? 'auf alle Pakete' : ''}`
	if(promo && promo === 'smaschke') {
		text = `Sandra Maschke Bonus aktiviert: Doppelte Coins ${!isDownXS? 'auf alle Pakete' : ''}`
	}

	return (
		<>
			<div
				key="promo_banner"
				style={{
					position: "fixed",
					display: isPromo ? "block" : "none",
					width: "100%",
					backgroundColor: "#43a547",
					textAlign: "center",
					color: "#eee",
					height: isDownXS? "3.6em" : "1.8rem",
					top: 0,
					fontSize: "1rem",
					fontWeight: 600,
					lineHeight: '1.8rem',
					zIndex: 1000,
					padding: '0 2rem'
				}}
			>
				{text}
				{" "}
				{!router.pathname.includes("register") && (
					<Link
						href="/register"
					>
						<span className={classes.regLink}>Jetzt registrieren</span>
					</Link>
				)}
			</div>
			<AppBar
				className={appBarClasses}
				style={{ borderRadius: "0", top: isPromo? isDownXS? "3.6rem" : "1.8rem" : 0 }}
			>
				<Toolbar
					className={classes.container}
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					{brandComponent}
					{rightLinks}
				</Toolbar>
			</AppBar>
		</>
	);
}

Header.defaultProp = {
	color: "white",
};

Header.propTypes = {
	color: PropTypes.oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"transparent",
		"white",
		"rose",
		"dark",
	]),
	rightLinks: PropTypes.node,
	leftLinks: PropTypes.node,
	brand: PropTypes.string,
	fixed: PropTypes.bool,
	absolute: PropTypes.bool,
	// this will cause the sidebar to change the color from
	// props.color (see above) to changeColorOnScroll.color
	// when the window.pageYOffset is heigher or equal to
	// changeColorOnScroll.height and then when it is smaller than
	// changeColorOnScroll.height change it back to
	// props.color (see above)
	changeColorOnScroll: PropTypes.shape({
		height: PropTypes.number.isRequired,
		color: PropTypes.oneOf([
			"primary",
			"info",
			"success",
			"warning",
			"danger",
			"transparent",
			"white",
			"rose",
			"dark",
		]).isRequired,
	}),
};
