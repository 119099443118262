import {
  defaultFont,
  primaryColor,
  primaryColor50,
  blackColor,
  whiteColor,
  grayColor,
  primaryBoxShadow,
  container,
  title,
  successColor,
} from "assets/jss/nextjs-material-kit.js";
import { createStyles } from "@material-ui/core/styles";

//HexToRgb Helper (MKNJS-Core)
const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

//Custom Styles

const customStyles = createStyles({
  container: {
    //fontFamily: "'Fira Sans', 'sans-serif'",
    paddingBottom: "15px",
    paddingTop: "15px",
    //color: whiteColor,
    ...container,
  },

  contentContainer: {
    backgroundColor: whiteColor,
    padding: 10,
    marginTop: 20,
    borderRadius: 5,
    paddingBottom: 30,
  },
  title: {
    ...title,
    display: "block",
    position: "relative",
    minHeight: "32px",
    textDecoration: "none",
    fontWeight: "bold",
    textAlign: "center",
    fonWeight: 800,
    fontSize: "1.7em",
    padding: "0",
  },

  hasPaddingTop: {
    paddingTop: "10px",
  },

  hasSpaceAround: {
    margin: "3em",
  },

  //Rows

  rowOdd: {
    backgroundColor: primaryColor,
    background: "linear-gradient(45deg, #542687 0%,#744BA1 50%,#542687 100%)",
    color: whiteColor + " !important",
  },

  rowGrey: {
    backgroundColor: "rgb(245, 245, 245)",
    backgroundSize: "cover",
    backgroundPosition: "bottom center",
    paddingBottom: 0,
  },

  primaryChip: {
    backgroundColor: "#744BA1",
    color: whiteColor,
    fontFamily: "'Fira Sans', 'sans-serif'",
    fontWeight: "bold",
    margin: "0.5em 1em",
    padding: "0.5em",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: primaryColor,
    },
  },

  switchOrderMobilePos1: {
    "@media (max-width: 576px)": {
      order: 1,
      textAlign: "center",
    },
  },

  switchOrderMobilePos2: {
    "@media (max-width: 576px)": {
      order: 2,
      textAlign: "center",
    },
  },

  //Form Elements

  largeSelect: {
    fontFamily: "inherit !important",

    fontFamily: "'Fira Sans', 'sans-serif'",

    textAlign: "center",
    padding: "12px 0 7px",
    fontSize: "18px",
    fontWeight: "800",
    color: "#333333 !important",
    lineHeight: "1.42857",
    textDecoration: "none",
    //textTransform: 'uppercase',
    letterSpacing: "0",
    "&:focus": {
      backgroundColor: "transparent",
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)",
    },
    "& + input + svg": {
      transition: "all 300ms linear",
    },
  },
  selectFormControl: {
    margin: "0 !important",
    marginBottom: "2px !important",
    "& > div": {
      "&:before": {
        borderBottomWidth: "1px !important",
        borderBottomColor: grayColor + " !important",
      },
      "&:after": {
        borderBottomColor: primaryColor + "!important",
      },
    },
  },
  selectLabel: {
    //fontSize: '17px',
    //textTransform: 'uppercase',
    color: grayColor + " !important",
    top: "8px",
  },
  selectMenu: {
    fontFamily: "'Fira Sans', 'sans-serif'",

    "& > div > ul": {
      border: "0",
      padding: "5px 0",
      margin: "0",
      boxShadow: "none",
      minWidth: "100%",
      borderRadius: "4px",
      boxSizing: "border-box",
      display: "block",
      //fontSize: '15px',
      textAlign: "left",
      listStyle: "none",
      backgroundColor: whiteColor,
      backgroundClip: "padding-box",
    },
    "& $selectPaper $selectMenuItemSelectedMultiple": {
      backgroundColor: "inherit",
    },
  },
  selectMenuItem: {
    //fontSize: '13px',
    fontFamily: "'Fira Sans', 'sans-serif'",

    padding: "10px",
    paddingLeft: "10px !important",
    margin: "10px",
    borderRadius: "2px",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "500",
    lineHeight: "2",
    whiteSpace: "nowrap",
    color: grayColor,
    paddingRight: "30px",
    "&:hover": {
      backgroundColor: primaryColor + "!important",
      color: whiteColor + "!important",
      ...primaryBoxShadow,
    },
  },
  selectMenuItemSelected: {
    backgroundColor: primaryColor + "!important",
    color: whiteColor + "!important",
  },
  selectMenuItemSelectedMultiple: {
    "&:hover": {
      backgroundColor: primaryColor + "!important",
      color: whiteColor + "!important",
      ...primaryBoxShadow,
      "&:after": {
        color: whiteColor,
      },
    },
    "&:after": {
      top: "16px",
      right: "12px",
      width: "12px",
      height: "5px",
      borderLeft: "2px solid currentColor",
      transform: "rotate(-45deg)",
      opacity: "1",
      color: grayColor[1],
      position: "absolute",
      content: "''",
      borderBottom: "2px solid currentColor",
      transition: "opacity 90ms cubic-bezier(0,0,.2,.1)",
    },
  },
  selectPaper: {
    boxSizing: "borderBox",
    borderRadius: "4px",
    padding: "0",
    minWidth: "100%",
    display: "block",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.26)",
    backgroundClip: "padding-box",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "transparent",
    maxHeight: "266px",
  },

  //SignUp

  signUpBox: {
    backgroundColor: "rgba(255,255,255,0.92)",
    color: blackColor + " !important",
    padding: "1em",
    borderRadius: "6px",
    marginTop: "0",
  },

  formBox: {
    marginTop: "140px",
    marginBottom: "70px",
    "@media (max-width: 576px)": {
      marginTop: "60px",
      marginBottom: "10px",
    },
  },

  //Main

  mainRaised: {
    padding: "2em",
    paddingTop: "0",
    margin: "-60px auto",
    "@media (max-width: 576px)": {
      marginTop: "-30px",
    },
    "@media (max-width: 830px)": {
      marginLeft: "10px",
      marginRight: "10px",
    },
    //margin: '-60px 30px 0px',
    borderRadius: "6px",

    maxWidth: "1200px !important",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },

  //Colors

  isPrimary: {
    color: primaryColor + "!important",
    "&hover": {
      color: primaryColor50,
    },
  },

  isPrimary50: {
    backgroundColor: primaryColor50,
  },

  isWhite: {
    color: whiteColor + " !important",
    "& *": {
      color: whiteColor + " !important",
    },
  },

  isGrey: {
    color: "rgba(180,180,180,1) !important",
  },

  isBlack: {
    color: blackColor + " !important",
  },

  hasBackgroundWhite: {
    color: primaryColor + " !important",
    backgroundColor: whiteColor + " !important",
  },

  //Shadows

  hasShadow: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
  },

  hasTextShadow: {
    textShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
  },

  //Helpers

  isUppercase: {
    textTransform: "uppercase",
  },

  hasBorderRadius: {
    borderRadius: 5,
  },
  //CTA

  ctaLink: {
    color: primaryColor,
    fontWeight: "800",
    textTransform: "uppercase",
    fontSize: "0.8em",

    "&:hover": {
      color: primaryColor,
      textDecoration: "underline",
    },

    "&:after": {
      content: '"»"',
      marginLeft: "0.5em",
    },
  },

  socialButton: {
    display: "inline-block",
    minWidth: "250px",
  },

  //images

  contentImage: {
    maxWidth: "100%",
    maxHeight: "440px",
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  //Footer

  footer: {
    fontSize: "0.8em !important",
    color: grayColor,
  },

  footerList: {
    display: "inline-block",
  },

  footerListItem: {
    display: "inline",
    textAlign: "center",

    padding: "2px 8px",
    paddingRight: "0",
  },

  footerLink: {
    color: grayColor,
    textTransform: "uppercase",
    fontWeight: "500",
    "&:hover": {
      color: grayColor,
      textDecoration: "underline",
    },
  },

  //Typography

  isTeaser: {
    fontSize: "1em",
    fontWeight: "600",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },

  isTeaserFullwidth: {
    width: "80%",
    margin: "0 auto",
  },

  isSmall: {
    fontSize: "0.75em",
  },

  small: {
    color: blackColor,
  },

  hasTextCentered: {
    textAlign: "center",
  },

  hasMarginTop10: {
    //marginTop: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },

  isBold: {
    fontWeight: "600 !important",
  },

  dividerSpacing: {
    backgroundColor: "rgba(0,0,0,0.05) !important",
    margin: "0 auto",
    marginTop: "3em",
    marginBottom: "3em",
  },

  checkmarkList: {
    listStyle: "none",
    textAlign: "left",
    marginTop: "1em",
    paddingLeft: 0,

    "& li": {
      display: "block",
      fontWeight: "400",
      marginTop: "0.25em",
      fontSize: "1em",

      //textTransform: "uppercase",

      "& strong": {
        fontWeight: "700",
      },

      "&:before": {
        content: '"✔"',
        marginRight: "0.3em",
        color: successColor,
      },
    },
  },

  input: {
    ...defaultFont,
    color: "#495057",
    height: "unset",
    "&,&::placeholder": {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1",
    },
    "&::placeholder": {
      color: "#AAAAAA",
    },
  },

  labelRoot: {
    ...defaultFont,
    color: "#AAAAAA !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px",
    },
  },

  h2: {
    fontSize: "1.8em",
  },

  h1: {
    fontSize: "2em"
  },

  //Responsive

  "@media (max-width: 767px)": {
    title: {
      fontSize: "1.7em",
      marginTop: 0,
      marginBottom: "1em",
    },

    h2: {
      fontSize: "1.8em",
    },
    h1: {
      fontSize: "2em"
    },
    checkmarkList: {
      marginTop: "2em",
      marginBottom: 0,
    },
  },
});

export default customStyles;
