import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/core components

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//styles

import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import customStyles from "assets/jss/custom/customStyles.js";
import { FormHelperText, InputLabel } from "@material-ui/core";

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
  selectIcon: {
    marginRight: "12px",
  },
});

interface Props {
  setType(type: "0" | "1");
  type: "0" | "1" | "";
  variant?: "normal" | "highlighted";
  isError?: boolean
}

export default function TypeSelect(props: Props) {
  const { variant = "normal", setType, type, isError= false } = props;
  const classes = useStyles();
  const handleSimple = (event) => {
    setType(event.target.value);
  };

  return (
    <FormControl
      fullWidth
      className={classes.selectFormControl}
      key="type"
      margin="dense"
      error={isError}
    >
      {!type && <InputLabel>Was bist du?</InputLabel>}
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: variant === "highlighted" ? classes.largeSelect : undefined,
          icon: classes.selectIcon,
        }}
        value={type}
        label="Was bist du?"
        inputProps={{
          name: "type",
          id: "type",
        }}
        onChange={handleSimple}
      >
        <MenuItem
          key="PATRON"
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value="0"
        >
          Ich bin ein Mann
        </MenuItem>
        <MenuItem
          key="CREATOR"
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value="1"
        >
          Ich bin ein Frau
        </MenuItem>
      </Select>
      {isError && <FormHelperText>Bitte wähle aus, was du bist</FormHelperText>}
    </FormControl>
  );
}
